import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketsList from "../TicketsList";
import api from "../../services/api";
import { parseISO } from "date-fns";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        width: "90%", // Define 90% da largura da tela
        maxWidth: "1400px", // Define um máximo absoluto para manter controle
    },
    calendar: {
        borderCollapse: "collapse",
        width: "100%",
        textAlign: "center",
        maxWidth: "700px",
    },
    calendarHeader: {
        backgroundColor: "#1976d2",
        color: "white",
        padding: "10px",
        fontSize: "16px",
    },
    calendarDay: {
        border: "1px solid #ccc",
        padding: "10px",
        height: "80px",
        width: "80px",
        cursor: "pointer",
        position: "relative",
        fontSize: "16px",
        verticalAlign: "top",
    },
    selectedDay: {
        backgroundColor: "#90caf9",
        color: "#000",
        fontWeight: "bold",
    },
    eventList: {
        marginTop: "5px",
        fontSize: "14px",
        textAlign: "left",
    },
    eventItem: {
        backgroundColor: "#f5f5f5",
        marginBottom: "3px",
        padding: "5px",
        borderRadius: "4px",
    },
    contentContainer: {
        display: "flex",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    calendarContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
    },
    ticketsContainer: {
        flex: 1,
        width: "90%", // Define 90% da largura da tela
        maxWidth: "500px",
    },
}));

const TicketAgendamentos = ({ modalOpen, onClose,
    status = "open",
    searchParam,
    showAll,
    selectedQueueIds,
    selectedWhatsappIds,
    selectedTime,
    selectedTag,
    dataInicio,
    dataFim }) => {
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateAgendamento, setSelectedDateAgendamento] = useState(null);
    const [commitments, setCommitments] = useState({});
    const classes = useStyles();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const init = async () => {
            setSelectedDate(null);
            setSelectedDateAgendamento("S/A");
            const { data } = await api.get(`/whatsappagendamentos/${selectedWhatsappIds}/${user.id}`);

            const agendamentosMap = data.reduce((map, item) => {
                const date = parseISO(item.agendamento).getDate();
                if (!map[date]) {
                    map[date] = [item];
                } else {
                    map[date].push(item);
                }
                return map;
            }, {});

            setCommitments(agendamentosMap);
        };
        init();
        setLoading(false);
    }, [modalOpen]);

    const handleDateClick = (day) => {
        if (day.length > 0) {
            setSelectedDate(parseISO(day[0].agendamento).getDate());
            console.log("SELECIONOU A DATA" + day[0].agendamento);
            console.log("SELECIONOU A DATA" + parseISO(day[0].agendamento).getDate());
            console.log("SELECIONOU A DATA" + JSON.stringify(day));
            setSelectedDateAgendamento(day[0].agendamento);
        } else {
            setSelectedDate(day);
            setSelectedDateAgendamento("S/A");
        }
    };


    const renderCalendar = () => {
        const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay();
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        const weeks = [];
        let week = new Array(firstDayOfMonth).fill(null);

        daysArray.forEach((day) => {
            week.push(day);
            if (week.length === 7) {
                weeks.push(week);
                week = [];
            }
        });

        if (week.length > 0) {
            while (week.length < 7) {
                week.push(null);
            }
            weeks.push(week);
        }

        return (
            <table className={classes.calendar}>
                <thead>
                    <tr>
                        <th className={classes.calendarHeader}>Dom</th>
                        <th className={classes.calendarHeader}>Seg</th>
                        <th className={classes.calendarHeader}>Ter</th>
                        <th className={classes.calendarHeader}>Qua</th>
                        <th className={classes.calendarHeader}>Qui</th>
                        <th className={classes.calendarHeader}>Sex</th>
                        <th className={classes.calendarHeader}>Sáb</th>
                    </tr>
                </thead>
                <tbody>
                    {weeks.map((week, index) => (
                        <tr key={index}>
                            {week.map((day, idx) => (
                                <td
                                    key={idx}
                                    className={`${classes.calendarDay} ${selectedDate === day ? classes.selectedDay : ""
                                        }`}
                                    onClick={day ? () => handleDateClick(commitments[day] ? commitments[day] : day) : null}
                                >
                                    {day || ""}
                                    {day && commitments[day] && (
                                        <div className={classes.eventList}>
                                            {commitments[day].map((event, idx) => (
                                                <div key={idx} className={classes.eventItem}>
                                                    {event.qtd}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const handleClose = () => {
        setLoading(true);
        onClose();
        setLoading(false);
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={handleClose}
            maxWidth="xl" // Define a largura máxima como "extra grande"
            fullWidth
            classes={{ paper: classes.dialogPaper }} // Estilo personalizado
        >
            <DialogTitle id="form-dialog-title">Agendamentos</DialogTitle>
            <DialogContent dividers style={{ overflowX: "hidden" }}>
                <div className={classes.contentContainer}>
                    <div className={classes.calendarContainer}>{renderCalendar()}</div>
                    <div className={classes.ticketsContainer}>
                        <TicketsList
                            status={status}
                            searchParam={searchParam}
                            showAll={showAll}
                            selectedQueueIds={selectedQueueIds}
                            selectedWhatsappIds={selectedWhatsappIds}
                            selectedTime={selectedTime}
                            selectedTag={selectedTag}
                            dataInicio={dataInicio}
                            dataFim={dataFim}
                            dataAgendamento={selectedDateAgendamento}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <ButtonWithSpinner
                    variant="contained"
                    type="button"
                    onClick={() => handleClose()}
                    color="primary"
                    loading={loading}
                >
                    Ok
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    );
};

export default TicketAgendamentos;
