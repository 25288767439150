import React, { useState, useEffect } from "react";
import { Button, TextField, FormControl, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketsQueueSelect from "../TicketsQueueSelect";
import TicketsTagSelect from "../TicketsTagSelect";
import TicketsSemRespostaSelect from "../TicketsSemRespostaSelect";
import TicketsWhatsappSelect from "../TicketsWhatsappSelect";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  filtersContainer: {
    padding: theme.spacing(2),
    background: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const TicketFilters = ({ onClose, user, resetFilter }) => {
  const classes = useStyles();
  
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const userQueueIds = user.queues.map((q) => q.id);
  const userWhatsappIds = user.whatsapps.map((w) => w.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState(userWhatsappIds || []);
  const [selectedTime, setSelectedTime] = useState("");

  const handleApplyFilters = () => {
    onClose({ dataInicio, dataFim, selectedQueueIds, selectedTagId, selectedWhatsappIds, selectedTime });
  };

  const handleLimpaFiltro = () => {
    setDataInicio("");
    setDataFim("");
    setSelectedQueueIds(userQueueIds || []);
    setSelectedTagId("");
    setSelectedWhatsappIds(userWhatsappIds || []);
    setSelectedTime("");
  };

  useEffect(() => {
    if (resetFilter) {
      handleLimpaFiltro();
    }
  }, [resetFilter]);

  return (
    <div className={classes.filtersContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TextField
              label="Data inicial"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dataInicio}
              onChange={(e) => setDataInicio(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TextField
              label="Data final"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dataFim}
              onChange={(e) => setDataFim(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TicketsWhatsappSelect
              selectedWhatsAppIds={selectedWhatsappIds}
              whatsApps={user?.whatsapps}
              onChange={(values) => setSelectedWhatsappIds(values)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TicketsQueueSelect
              selectedQueueIds={selectedQueueIds}
              userQueues={user?.queues}
              onChange={(values) => setSelectedQueueIds(values)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TicketsTagSelect
              filialId={user.filial_icode}
              userId={user.id}
              tag={selectedTagId}
              onChange={(values) => setSelectedTagId(values)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <TicketsSemRespostaSelect
              selectedTime={selectedTime}
              onChange={(value) => setSelectedTime(value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.actionsContainer}>
        <Button onClick={handleLimpaFiltro} color="secondary" variant="outlined">
          Limpar Filtros
        </Button>
        <ButtonWithSpinner variant="contained" onClick={handleApplyFilters} color="primary">
          Filtrar
        </ButtonWithSpinner>
      </div>
    </div>
  );
};

export default TicketFilters;
