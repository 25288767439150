import api from "../../services/api";

const useTags = (userId, filialId) => {
	const findAll = async () => {
        const { data } = await api.get(`/tags/${userId}/${filialId}`);
        return data;
    }

	return { findAll };
};

export default useTags;
