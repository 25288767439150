import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { parseISO } from "date-fns";

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const isTempoLeitura = (selectedTime, ticket) => {
	let retorno = false;
	if (selectedTime === '30m') {
		const trintaMinutos = new Date(new Date() - 30 * 60 * 1000);
		const dataMensagem = new Date(ticket.dataUltimaMensagem);
		if (dataMensagem < trintaMinutos && ticket.unreadMessages > 0) {
			retorno = true;
		}
	} else if (selectedTime === '1h') {
		const umaHora = new Date(new Date() - 1 * 60 * 60 * 1000);
		const dataMensagem = new Date(ticket.dataUltimaMensagem);
		if (dataMensagem < umaHora && ticket.unreadMessages > 0) {
			retorno = true;
		}
	} else if (selectedTime === '2h') {
		const duasHoras = new Date(new Date() - 2 * 60 * 60 * 1000);
		const dataMensagem = new Date(ticket.dataUltimaMensagem);
		if (dataMensagem < duasHoras && ticket.unreadMessages > 0) {
			retorno = true;
		}
	} else if (selectedTime === '3h') {
		const tresHoras = new Date(new Date() - 3 * 60 * 60 * 1000);
		const dataMensagem = new Date(ticket.dataUltimaMensagem);
		if (dataMensagem < tresHoras && ticket.unreadMessages > 0) {
			retorno = true;
		}
	} else if (selectedTime === 'Aguardando resposta') {
		if (ticket.unreadMessages > 0) {
			retorno = true;
		}
	} else if (selectedTime === 'Respondido') {
		if (ticket.unreadMessages == 0) {
			retorno = true;
		}
	} else if (selectedTime == 'Aguardando 1 interacao') {

	} else if (selectedTime === 'Todos') {
		retorno = true;
	}
	return retorno;
}

const isBetweenDates = (dataAtual, dataInicio, dataFim) => {
	if (dataInicio && dataFim && dataAtual) {

		const dataAtualDate = parseISO(dataAtual);
		const dataInicioDate = parseISO(dataInicio);
		const dataFimDate = parseISO(dataFim);

		dataAtualDate.setHours(0, 0, 0, 0);
		dataInicioDate.setHours(0, 0, 0, 0);
		dataFimDate.setHours(0, 0, 0, 0);

		return dataAtualDate >= dataInicioDate && dataAtualDate <= dataFimDate;
	}
	return true;
};

const filtrarLista = (ticket, action) => {
	let isSelected = false;
	if (action?.selectedWhatsappIds) {
		isSelected = action?.selectedWhatsappIds?.includes(ticket.whatsappId);
	} else {
		isSelected = true;
	}
	if (!isSelected) return false;

	if (action?.selectedQueueIds) {
		isSelected = action?.selectedQueueIds?.includes(ticket.queueId);
	} else {
		isSelected = true;
	}
	if (!isSelected) return false;

	if (action?.selectedTag) {
		isSelected = action?.selectedTag?.includes(ticket.tagId);
	} else {
		isSelected = true;
	}
	if (!isSelected) return false;

	isSelected = isBetweenDates(ticket?.dataUltimaMensagem, action?.dataInicio, action?.dataFim);
	if (!isSelected) return false;

	isSelected = isTempoLeitura(action?.selectedTime, ticket);
	if (!isSelected) return false;
	return isSelected;
}

const regrasLista = (ticket) => {
	if (ticket?.queue) {
		return ticket?.queue?.mostrarFilaMensagens;
	}
	return true;
}

const reducer = (state, action) => {

	if (action.type === "LOAD_TICKETS_SEARCH") {
		const newTickets = action.payload;
		state = [];
		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			let isSelected = regrasLista(ticket);
			if (isSelected) {
				isSelected = filtrarLista(ticket, action);
			}
			if (isSelected) {
				if (ticketIndex !== -1) {
					state[ticketIndex] = ticket;
					if (ticket.unreadMessagesLayout > 0) {
						state.unshift(state.splice(ticketIndex, 1)[0]);
					}
				} else {
					state.push(ticket);
				}
			} else {
				if (ticketIndex !== -1) {
					state.splice(ticketIndex, 1);
				}
			}
		});

		return [...state];
	}

	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			let isSelected = regrasLista(ticket);
			if (isSelected) {
				isSelected = filtrarLista(ticket, action);
			}
			if (isSelected) {
				if (ticketIndex !== -1) {
					state[ticketIndex] = ticket;
					if (ticket.unreadMessagesLayout > 0) {
						state.unshift(state.splice(ticketIndex, 1)[0]);
					}
				} else {
					state.push(ticket);
				}
			} else {
				if (ticketIndex !== -1) {
					state.splice(ticketIndex, 1);
				}
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			let ticket = state[ticketIndex];
			let isSelected = regrasLista(ticket);
			if (isSelected) {
				isSelected = filtrarLista(ticket, action);
			}
			if (isSelected) {
				state[ticketIndex].unreadMessagesLayout = 0;
			} else {
				state.splice(ticketIndex, 1);
			}
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		let isSelected = regrasLista(ticket);
		if (isSelected) {
			isSelected = filtrarLista(ticket, action);
		}
		if (isSelected) {
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
			} else {
				state.unshift(ticket);
				if (state.length >= 20) {
					state.pop();
				}
			}
		} else {
			if (ticketIndex !== -1) {
				state.splice(ticketIndex, 1);
			}
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		let isSelected = regrasLista(ticket);
		if (isSelected) {
			isSelected = filtrarLista(ticket, action);
		}
		if (isSelected) {
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				state.unshift(state.splice(ticketIndex, 1)[0]);
			} else {
				state.unshift(ticket);
				if (state.length >= 20) {
					state.pop();
				}
			}
		} else {
			if (ticketIndex !== -1) {
				state.splice(ticketIndex, 1);
			}
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const TicketsList = (props) => {
	const { status, searchParam, showAll, selectedQueueIds, selectedWhatsappIds, selectedTime, selectedTag, dataInicio, dataFim, dataAgendamento, updateCount, style, onSelectTicket } =
		props;
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const [selectedItems, setSelectedItems] = useState([]);
	const { tickets, hasMore, loading } = useTickets({
		pageNumber,
		searchParam,
		status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
		whatsappIds: JSON.stringify(selectedWhatsappIds),
		selectedTime,
		selectedTag,
		dataInicio,
		dataFim,
		dataAgendamento
	});

	useEffect(() => {
		dispatch({
			type: "RESET"
		});
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, selectedQueueIds, selectedWhatsappIds, selectedTime, selectedTag, dataInicio, dataFim, dataAgendamento]);

	useEffect(() => {
		if (!status) return;
		dispatch({
			type: "LOAD_TICKETS",
			payload: tickets,
			searchParam,
			selectedQueueIds,
			selectedWhatsappIds,
			selectedTime,
			selectedTag,
			dataInicio,
			dataFim,
		});
	}, [tickets, status]);

	useEffect(() => {
		if (!searchParam) return;
		dispatch({
			type: "LOAD_TICKETS_SEARCH",
			payload: tickets,
			searchParam,
			selectedQueueIds,
			selectedWhatsappIds,
			selectedTime,
			selectedTag,
			dataInicio,
			dataFim,
		});
	}, [tickets, searchParam]);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		const shouldUpdateTicket = ticket =>
			(
				(ticket.isGroup && ticket.user?.filial_icode === user?.filial_icode)
				|| ((ticket.userId === user?.id || ticket.userIdAtendenteFilial === user?.id) || (user.gestor && ticket.user?.filial_icode === user?.filial_icode))
				|| ticket.status == 'pending'
			)
			&& (selectedWhatsappIds.indexOf(ticket.whatsappId) > -1);

		const notBelongsToUserQueues = ticket =>
			(ticket.whatsappId && selectedWhatsappIds.indexOf(ticket.whatsappId) === -1);

		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {
			if (searchParam) return;
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({
					type: "DELETE_TICKET",
					payload: data.ticket.id,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				}
				);
			}

			if (data.action === "delete") {
				dispatch({
					type: "DELETE_TICKET",
					payload: data.ticketId,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				});
			}
		});

		socket.on("appMessage", data => {
			if (searchParam) return;
			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				});
			}
		});

		socket.on("contact", data => {
			if (searchParam) return;
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
					searchParam,
					selectedQueueIds,
					selectedWhatsappIds,
					selectedTime,
					selectedTag,
					dataInicio,
					dataFim,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [status, showAll, user, selectedQueueIds, selectedTime, searchParam]);

	useEffect(() => {
		if (typeof updateCount === "function") {
			updateCount(ticketsList.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketsList]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	// const handleCheckboxChange = (id) => {
	// 	setSelectedItems((prevSelected) =>
	// 		prevSelected.includes(id)
	// 			? prevSelected.filter((item) => item !== id) // Remove se já estiver selecionado
	// 			: [...prevSelected, id] // Adiciona se não estiver selecionado
	// 	);
	// 	onSelectTicket(selectedItems);
	// 	console.log(id);
	// 	console.log(selectedItems.length);
	// };


	const handleCheckboxChange = (id) => {
		setSelectedItems((prevSelected) => {
			const updatedItems = prevSelected.includes(id)
				? prevSelected.filter((item) => item !== id)
				: [...prevSelected, id];

			// Atualize selectedItems com o valor atualizado
			onSelectTicket(updatedItems); // Passa o valor atualizado diretamente
			return updatedItems; // Retorna o valor atualizado para o setSelectedItems
		});
		console.log(id);
		console.log(selectedItems.length);
	};

	return (
		<Paper className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{loading && <TicketsListSkeleton />}
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (<>{ticketsList.map(ticket => (
						<TicketListItem
							ticket={ticket}
							key={ticket.id}
							selectedItems={selectedItems}
							multiplaSelecao={dataAgendamento ? false : true}
							onCheckboxChange={handleCheckboxChange} />
					))}</>)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
		</Paper>
	);
};

export default TicketsList;