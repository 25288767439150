import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketsUserTransferirSelect from "../TicketsUserTransferirSelect";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
}));

const TicketTransferirModal = ({ modalOpen, onClose, user }) => {
	const [selectedUser, setSelectedUser] = useState("");
	const [loading, setLoading] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		setLoading(false);
		setSelectedUser("");
	}, [modalOpen]);

	const handleClose = async () => {
		setLoading(true);
		await onClose(selectedUser);
		setLoading(false);
	};

	const handleCancel = () => {
		setSelectedUser("");
		onClose();
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose}>
			<DialogTitle id="form-dialog-title">
				Transferir lead para outro atendente
			</DialogTitle>
			<DialogContent dividers>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TicketsUserTransferirSelect
						style={{ marginLeft: 6 }}
						filialId={user.filial_icode}
						onChange={(value) => setSelectedUser(value)}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleCancel()}
					color="secondary"
					variant="outlined"
					disabled={loading}
				>
					Cancelar
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					onClick={() => handleClose()}
					color="primary"
					loading={loading}
				>
					Transferir
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default TicketTransferirModal;
