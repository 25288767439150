import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField, ListItemText } from "@mui/material";

const TicketsWhatsappSelect = ({ whatsApps, selectedWhatsAppIds = [], onChange }) => {
	const [selectedIds, setSelectedIds] = useState(selectedWhatsAppIds);

	// Define o primeiro item como selecionado por padrão
	useEffect(() => {
		if (whatsApps.length > 0 && selectedIds.length === 0) {
			setSelectedIds([whatsApps[0].id]);
			onChange([whatsApps[0].id]);
		}else{
			setSelectedIds(selectedWhatsAppIds);
		}
	}, [whatsApps, selectedIds, onChange]);

	const handleChange = (event, newValue) => {
		const newIds = newValue.map((item) => item.id);
		setSelectedIds(newIds);
		onChange(newIds);
	};

	return (
		<Autocomplete
			multiple
			options={whatsApps}
			getOptionLabel={(option) => option.name}
			value={whatsApps.filter((whatsApp) => selectedIds.includes(whatsApp.id))}
			onChange={handleChange}
			disableCloseOnSelect
			renderOption={(props, option, { selected }) => (
				<li {...props}>
					<Checkbox checked={selected} color="primary" />
					<ListItemText primary={option.name} />
				</li>
			)}
			renderInput={(params) => <TextField {...params} label="Filtrar por conexão" variant="outlined" margin="dense" />}
		/>
	);
};

export default TicketsWhatsappSelect;
