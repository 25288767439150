import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const options = [
  { label: "Todos", value: "Todos" },
  { label: "Aguardando resposta", value: "Aguardando resposta" },
  { label: "Respondido", value: "Respondido" },
  { label: "Aguardando 1º interação", value: "Aguardando 1 interacao" },
  { label: "Aguardando mais que 30m", value: "30m" },
  { label: "Aguardando mais que 1h", value: "1h" },
  { label: "Aguardando mais que 2h", value: "2h" },
  { label: "Aguardando mais que 3h", value: "3h" }
];

const TicketsSemRespostaSelect = ({ selectedTime, onChange }) => {
  const [time, setTime] = useState(selectedTime || "");

  useEffect(() => {
    setTime(selectedTime || "");
  }, [selectedTime]);

  const handleChange = (event, newValue) => {
    setTime(newValue?.value || "");
    if (onChange) onChange(newValue?.value || "");
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      value={options.find((option) => option.value === time) || null}
      onChange={handleChange}
      renderOption={(props, option) => (
        <MenuItem {...props}>{option.label}</MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Filtrar por resposta" variant="outlined" margin="dense" />
      )}
    />
  );
};

export default TicketsSemRespostaSelect;
