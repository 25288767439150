import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useQueues from "../../hooks/useQueues";

const TicketsAlterarEtapaSelect = ({ userId, onChange }) => {
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { findAll: findAllQueues } = useQueues(userId);
  

  useEffect(() => {
    const init = async () => {
      const listQueues = await findAllQueues(userId);
      setQueues(listQueues);
    };
    init();
  }, [userId]);

  const handleChange = (event, newValue) => {
    setSelectedQueue(newValue);
    if (onChange) onChange(newValue?.id || "");
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      options={queues}
	  size="small"
	  sx={{ width: 400 }}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : selectedQueue?.id === option.id
          ? option.name // Exibe apenas o nome quando selecionado
          : `${option.id} - ${option.name}` // Exibe ID na busca
      }
      renderOption={(props, option) => <li {...props}>{option.name}</li>} // Exibe apenas o nome na lista
      value={selectedQueue}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label="Selecione uma etapa" variant="outlined" size="small" />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText="Nenhuma etapa encontrado"
    />
  );
};

export default TicketsAlterarEtapaSelect;
