import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useUsers from "../../hooks/useUsers";

const TicketsUserTransferirSelect = ({ filialId, onChange }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { findAll: findAllUsers } = useUsers(filialId);

  useEffect(() => {
    const init = async () => {
      const listUsers = await findAllUsers(filialId);
      setUsers(listUsers);
    };
    init();
  }, [filialId]);

  const handleChange = (event, newValue) => {
    setSelectedUser(newValue);
    if (onChange) onChange(newValue?.id || "");
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      options={users}
	  size="small"
	  sx={{ width: 400 }}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : selectedUser?.id === option.id
          ? option.name // Exibe apenas o nome quando selecionado
          : `${option.id} - ${option.name}` // Exibe ID na busca
      }
      renderOption={(props, option) => <li {...props}>{option.name}</li>} // Exibe apenas o nome na lista
      value={selectedUser}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label="Selecione um atendente" variant="outlined" size="small" />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText="Nenhum atendente encontrado"
    />
  );
};

export default TicketsUserTransferirSelect;
