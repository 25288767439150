import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({ userQueues, selectedQueueIds = [], onChange }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setSelectedValues(userQueues.filter(queue => selectedQueueIds.includes(queue.id)));
  }, [selectedQueueIds, userQueues]);

  const handleChange = (event, newValue) => {
    // Verifica se "Selecionar Tudo" foi clicado
    if (newValue.some((option) => option.id === "toggle-all")) {
      if (selectedQueueIds.length === userQueues.length) {
        setSelectedValues([]);
        onChange([]);
      } else {
        setSelectedValues(userQueues);
        onChange(userQueues.map((queue) => queue.id));
      }
    } else {
      setSelectedValues(newValue);
      onChange(newValue.map((queue) => queue.id));
    }
  };

  return (
    <Autocomplete
      multiple
      options={[{ id: "toggle-all", name: "Selecionar Tudo" }, ...userQueues]}
      getOptionLabel={(option) => option.name}
      value={selectedValues}
      disableCloseOnSelect
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props}>
          <Checkbox
            color="primary"
            checked={
              option.id === "toggle-all"
                ? selectedQueueIds.length === userQueues.length
                : selected
            }
            indeterminate={
              option.id === "toggle-all" &&
              selectedQueueIds.length > 0 &&
              selectedQueueIds.length < userQueues.length
            }
            style={{ color: option.color || "inherit" }}
          />
          <ListItemText primary={option.name} />
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={i18n.t("ticketsQueueSelect.placeholder")} variant="outlined" margin="dense" />
      )}
    />
  );
};

export default TicketsQueueSelect;
