import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PowerIcon from "@material-ui/icons/Power";

// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketFiltersModal from "../TicketFiltersModal";
import TicketTransferirModal from "../TicketTransferirModal";
import TicketAlterarEtapa from "../TicketAlterarEtapa";
import TicketsList from "../TicketsList";
import TicketsWhatsappList from "../TicketsWhatsappList";
import TabPanel from "../TabPanel";


import { TextField, FormControl, Grid } from "@material-ui/core";
import TicketsQueueSelect from "../TicketsQueueSelect";
import TicketsTagSelect from "../TicketsTagSelect";
import TicketsSemRespostaSelect from "../TicketsSemRespostaSelect";
import TicketsWhatsappSelect from "../TicketsWhatsappSelect";
import TicketAgendamentos from "../TicketAgendamentos";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
// import { Can } from "../Can";
// import TicketsQueueSelect from "../TicketsQueueSelect";
// import TicketsTagSelect from "../TicketsTagSelect";
// import TicketsSemRespostaSelect from "../TicketsSemRespostaSelect";
// import TicketsWhatsappSelect from "../TicketsWhatsappSelect";
// import { Button } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";

import { Menu, MenuItem, Button } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import api from "../../services/api";




import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: theme.palette.background.default,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column", // Exibe em coluna por padrão
    gap: 8, // Espaço entre os elementos
    "@media (min-width: 600px)": { // Exibe em linha a partir de uma tela média
      flexDirection: "row",
      alignItems: "center",
    },
  },

  ticketOptionsButton: {
    width: "100%", // O Paper ocupa toda a largura da tela
    maxWidth: 400, // Limite de largura (opcional)
    padding: 8, // Espaçamento interno para melhor aparência
    marginLeft: 0, // Alinha à esquerda
    display: "flex", // Exibe os botões em linha
    gap: 10, // Espaçamento uniforme entre os botões
  },

  serachInputWrapper: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },

  botaoVoltarCRM: {
    position: "fixed",
    width: "50px",
    height: "50px",
    bottom: "50px",
    left: "40px",
    backgroundColor: "#4CAF50",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    fontSize: "20px",
    boxShadow: "1px 1px 2px #888",
    zIndex: 1000,
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [ticketTransferirModalOpen, setTicketTransferirModalOpen] = useState(false);
  const [ticketAlterarEtapaModalOpen, setTicketAlterarEtapaModalOpen] = useState(false);
  const [ticketAgendamentoOpen, setTicketAgendamentoOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const userWhatsappIds = user.whatsapps.map((w) => w.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState(userWhatsappIds || []);
  const [selectedTime, setSelectedTime] = useState("Todos");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState([]);


  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    //  eslint-disable-next-line, react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);


  useEffect(() => {
    let interval;
    if (process.env.REACT_APP_GESTAO === "ativo") {
      interval = setInterval(() => {
        handleAtualizarFilters();
      }, 30000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);


  // let searchTimeout;

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      const searchedTerm = e.target.value.toLowerCase();

      // clearTimeout(searchTimeout);

      if (searchedTerm === "") {
        setSearchParam(searchedTerm);
        // setTab("open");
        return;
      }

      setSearchParam(searchedTerm);

      // searchTimeout = setTimeout(() => {
      //   setSearchParam(searchedTerm);
      // }, 500);
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleAtualizarFilters = () => {
    setSelectedQueueIds(null);
    setSelectedTagId(null);
    setSelectedWhatsappIds(null);
    setSelectedTime(null);
    setDataInicio(null);
    setDataFim(null);

    setTimeout(() => {
      setSelectedQueueIds(selectedQueueIds || []);
      setSelectedTagId(selectedTagId || "");
      setSelectedWhatsappIds(selectedWhatsappIds || []);
      setSelectedTime(selectedTime || "");
      setDataInicio(dataInicio || "");
      setDataFim(dataFim || "");
    }, 10);
  };

  const handleTransferirLead = async (value) => {
    if (value) {
      try {
        await api.post(`/transferirlead`, {
          userLogadoId: user?.id,
          userDestinoId: value,
          leads: selectedTickets
        });
      } catch (err) {
        toastError(err);
      }
    }
    setTicketTransferirModalOpen(false);
  };

  const handleAlterarEtapa = async (value) => {
    if (value) {
      try {
        await api.post(`/transferiretapaticket`, {
          etapaId: value,
          leads: selectedTickets
        });
      } catch (err) {
        toastError(err);
      }
    }
    setTicketAlterarEtapaModalOpen(false);
  };

  const handleMarcarLidoLead = async () => {
    if (selectedTickets?.length > 0) {
      try {
        await api.post(`/marcarrespondido`, {
          ticketsId: selectedTickets
        });
      } catch (err) {
        toastError(err);
      }
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (menu) => {
    if (menu === "transferLead") {
      setTicketTransferirModalOpen(true);
    } else if (menu === "alterarLead") {
      setTicketAlterarEtapaModalOpen(true);
    } else if (menu === "marcarLido") {
      handleMarcarLidoLead();
    }
    setAnchorEl(null);
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        whatsApps={user?.whatsapps}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <TicketTransferirModal
        modalOpen={ticketTransferirModalOpen}
        user={user}
        onClose={(value) => handleTransferirLead(value)}
      />
      <TicketAlterarEtapa
        modalOpen={ticketAlterarEtapaModalOpen}
        user={user}
        onClose={(value) => handleAlterarEtapa(value)}
      />
      <TicketAgendamentos
        modalOpen={ticketAgendamentoOpen}
        user={user}
        status="open"
        searchParam={searchParam}
        showAll={showAllTickets}
        selectedQueueIds={selectedQueueIds}
        selectedWhatsappIds={selectedWhatsappIds}
        selectedTime={selectedTime}
        selectedTag={selectedTagId}
        dataInicio={dataInicio}
        dataFim={dataFim}
        onClose={() => setTicketAgendamentoOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"conexoes"}
            icon={<PowerIcon />}
            label={"Conexões"}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>

      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.serachInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onKeyDown={handleSearch}
          />
        </div>
      </Paper>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <Paper square elevation={0} className={classes.ticketOptionsButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();  // Previne a expansão ao clicar no botão
                    setNewTicketModalOpen(true);
                  }}
                >
                  Novo lead
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();  // Previne a expansão
                    setTicketAgendamentoOpen(true);
                  }}
                >
                  Agendamentos
                </Button>

                {selectedTickets.length > 0 && (
                  <div>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();  // Previne a expansão
                        handleOpenMenu(e);
                      }}
                      variant="contained"
                      size="small"
                    >
                      <MoreVert />
                    </Button>

                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                      <MenuItem onClick={(e) => { handleCloseMenu("alterarLead"); e.stopPropagation(); }}>
                        Alterar etapa do lead
                      </MenuItem>
                      <MenuItem onClick={(e) => { handleCloseMenu("marcarLido"); e.stopPropagation(); }}>
                        Marcar como respodido
                      </MenuItem>
                      <MenuItem onClick={(e) => { handleCloseMenu("transferLead"); e.stopPropagation(); }}>
                        Transferir lead
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </Paper>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div style={{ margin: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Data inicial"
                      variant="outlined"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={dataInicio}
                      onChange={(e) => setDataInicio(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Data final"
                      variant="outlined"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={dataFim}
                      onChange={(e) => setDataFim(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TicketsWhatsappSelect
                      selectedWhatsAppIds={selectedWhatsappIds}
                      whatsApps={user?.whatsapps}
                      onChange={(values) => setSelectedWhatsappIds(values)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TicketsSemRespostaSelect
                      selectedTime={selectedTime}
                      onChange={(value) => setSelectedTime(value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TicketsQueueSelect
                      selectedQueueIds={selectedQueueIds}
                      userQueues={user?.queues}
                      onChange={(values) => setSelectedQueueIds(values)}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
            <TicketsTagSelect
              filialId={user.filial_icode}
              userId={user.id}
              tag={selectedTagId}
              onChange={(values) => setSelectedTagId(values)}
            />
          </Grid> */}
                </Grid>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        {/*  <TabPanel value="open" name="open" className={classes.ticketsWrapper}>*/}
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
        <a href={localStorage.getItem("url_retorno_crm") + "/app/crm/board/index.xhtml?funil=" + localStorage.getItem("funilId")}
          className={classes.botaoVoltarCRM} target="_self" title="Retornar ao board CRM">
          <i style={{ marginTop: "16px" }} className="fa fa-mail-reply"></i>
        </a>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                Contatos
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={groupCount}
                color="primary"
              >
                Grupos
              </Badge>
            }
            value={"grupos"}
          />
          {user.renderAbaAguardando ? (
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                >
                  {i18n.t("ticketsList.pendingHeader")}
                </Badge>
              }
              value={"pending"}
            />
          ) : (<></>)}
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            searchParam={searchParam}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedWhatsappIds={selectedWhatsappIds}
            selectedTime={selectedTime}
            selectedTag={selectedTagId}
            dataInicio={dataInicio}
            dataFim={dataFim}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
            onSelectTicket={(value) => setSelectedTickets(value)}
          />
          <TicketsList
            status="grupos"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedWhatsappIds={selectedWhatsappIds}
            selectedTime={selectedTime}
            selectedTag={selectedTagId}
            dataInicio={dataInicio}
            dataFim={dataFim}
            updateCount={(val) => setGroupCount(val)}
            style={applyPanelStyle("grupos")}
          />
          {user.renderAbaAguardando ? (
            <TicketsList
              status="pending"
              selectedQueueIds={selectedQueueIds}
              selectedWhatsappIds={selectedWhatsappIds}
              selectedTime={selectedTime}
              selectedTag={selectedTagId}
              dataInicio={dataInicio}
              dataFim={dataFim}
              updateCount={(val) => setPendingCount(val)}
              style={applyPanelStyle("pending")}
            />
          ) : (<></>)}
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="conexoes" className={classes.ticketsWrapper}>
        <TicketsWhatsappList
          whatsAppsIds={selectedWhatsappIds} />
      </TabPanel>
    </Paper >
  );
};

export default TicketsManager;
